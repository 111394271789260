<template>
  <div class="d-flex justify-center">
    <base-animation
      :class="classes"
      ref="animation"
      :timeout="timeoutComputed"
      :enter="animation.enter"
      :exit="animation.exit"
      :value="value"
    >
      <div @click="onClick">
        <slot name="default" :message="message">
          <peeps-alert-above
            v-if="peepsAbove"
            v-bind="alertAttrs"
            :width="width"
            :color="color"
          >
            {{ message }}
          </peeps-alert-above>
          <peeps-alert-right
            :color="color"
            v-else-if="peepsLeft"
            v-bind="alertAttrs"
            :width="width"
          >
            {{ message }}
          </peeps-alert-right>
        </slot>
      </div>
    </base-animation>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: ''
    },
    peepsAbove: {
      type: Boolean
    },
    peepsLeft: {
      type: Boolean,
      default: true
    },
    animateLeft: {
      type: Boolean,
      default: true
    },
    animateDown: {
      type: Boolean
    },
    animateUp: {
      type: Boolean
    },
    width: {
      type: String
    },
    value: {
      type: Boolean
    },
    color: {
      type: String,
      default: 'secondary'
    },
    alertAttrs: {
      type: Object
    },
    classes: {
      type: String
    },
    timeout: {
      type: Number
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PeepsAlertRight: () => import('./PeepsAlertRight.vue'),
    PeepsAlertAbove: () => import('./PeepsAlertAbove.vue')
  },
  computed: {
    timeoutComputed() {
      if (this.timeout) {
        return this.timeout
      } else {
        return 5000 + 100 * String(this.message).length
      }
    },
    animation() {
      let anim = { enter: '', exit: '' }
      if (this.animateLeft) {
        anim.enter = 'animate__fadeInLeft'
        anim.exit = 'animate__fadeOutLeft'
      }
      if (this.animateUp) {
        anim.enter = 'animate__fadeInDown'
        anim.exit = 'animate__fadeOutUp'
      }
      if (this.animateDown) {
        anim.enter = 'animate__fadeInUp'
        anim.exit = 'animate__fadeOutDown'
      }
      return anim
    }
  },
  methods: {
    show() {
      this.animate()
    },
    animate() {
      if (this.persistent) {
        return
      }
      this.$refs.animation.animate()
    },
    onClick() {
      if (this.persistent) {
        return
      }
      this.$refs.animation.animateExit()
    }
  }
}
</script>

<style scoped>
.float-bottom-left {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 20px;
  z-index: 999;
}
</style>
